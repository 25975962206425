import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { graphql, Link } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// import loadable from "@loadable/component"
import AdSense from "react-adsense"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControl from "@material-ui/core/FormControl"
import Hidden from "@material-ui/core/Hidden"
import Popper from "@material-ui/core/Popper"
import Fade from "@material-ui/core/Fade"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import Avatar from "@material-ui/core/Avatar"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
// import Skeleton from "@material-ui/lab/Skeleton"

import CloseIcon from "@material-ui/icons/Close"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import RedditIcon from "@material-ui/icons/Reddit"
import LinkedIn from "@material-ui/icons/LinkedIn"
import LinkIcon from "@material-ui/icons/Link"
import { FiShare } from "react-icons/fi"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"

// import CommentSection from "../../components/CommentModule"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import headshot from "../../assets/images/headshot_1_250x250.webp"
import coffee from "../../assets/images/coffee.svg"
import AdBlockerHand from "../../assets/images/adblockVector.svg"

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
import Button from "@material-ui/core/Button"
deckDeckGoHighlightElement()

const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  outerCtr: {
    paddingTop: "2em",
  },
  paper: {
    borderRadius: 4,
    padding: theme.spacing(1),
    inset: `0px auto auto 0px`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `rgba(230,230,230,1) 0px 1px 4px`,
  },
  postDate: {
    color: "#757575",
    fontSize: ".865rem",
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  postTitle: {
    color: "#292929",
    fontSize: "2.25rem",
    lineHeight: "1.25em",
    fontFamily: "Open Sans",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.375em",
      lineHeight: "1.35em",
    },
  },
  postShortTitle: {
    paddingTop: ".75em",
    paddingBottom: "1em",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#757575",
    fontSize: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  postImage: {},
  postImgSubtitle: {
    fontWeight: 700,
    fontSize: ".8125rem",
    color: "#757575",
  },
  postBody: {
    paddingTop: "1.5em",
    color: "#292929",
    fontSize: "1.05rem",
    lineHeight: "2rem",
    fontFamily: "Open Sans",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      paddingTop: "1rem",
    },
    "& iframe": {
      marginTop: "1.5em",
    },
    "& deckgo-highlight-code": {
      fontSize: ".8rem",
      lineHeight: "24px",
    },
    "& code": {
      backgroundColor: "#a4ecd6",
    },
    "& hr": {
      margin: "2em 0",
    },
    "& em": {
      fontFamily: "Open Sans",
    },
    "& blockquote": {
      background: "#f9f9f9",
      borderLeft: "7px solid #ccc",
      margin: "2em 10px",
      padding: ".5em 10px",
      "& p": {
        display: "inline",
        fontFamily: "Open Sans",
      },
      "&:before": {
        color: "#ccc",
        content: "open-quote",
        fontSize: "2em",
        lineHeight: ".1em",
        marginRight: ".15em",
        verticalAlign: "0em",
      },
    },
    "& a": {
      textDecoration: "underline",
    },
    "& img": {
      maxWidth: "-webkit-fill-available",
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& > p": {
      // fontSize: "1.05rem",
      fontFamily: "Open Sans, Roboto, sans-serif",
      lineHeight: "1.75",
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "1rem",
      // },
    },
    "& > p:not(:first-child)": {
      marginTop: "2em",
    },
    "& strong": {
      color: primary,
      fontFamily: "Open Sans",
    },
    "& ul": {
      "& li": {
        listStyleType: "square",
      },
      "& li::marker": {
        color: primary,
        fontSize: "1.5rem",
      },
      "& > li:first-child": {
        marginTop: "2rem",
      },
      "& > li:not(:first-child)": {
        marginTop: ".5em",
        // "& :nth-child(1)": {
        //   marginTop: 50,
        // },
      },
      "& > li:last-child": {
        marginBottom: "2rem",
      },
    },
    "& ol": {
      "& li": {
        // listStyleType: "square",
        marginTop: "1em",
        fontWeight: 500,
      },
      "& li::marker": {
        color: primary,
        fontSize: "1.5rem",
        marginRight: 10,
        fontWeight: 900,
      },
      "& > li:not(:first-child)": {
        marginTop: "1em",
      },
    },
    "& > h2, h1, h3": {
      fontFamily: "Open Sans",
      // marginTop: "2em",
      marginTop: "2rem",
      marginBottom: ".75rem",
      borderLeft: `4px solid ${primary}`,
      paddingLeft: 20,
    },
    "& > h3, h4, h5, h6": {
      fontSize: "1.5rem",
      fontFamily: "Open Sans",
      marginTop: "2rem",
      marginBottom: ".75rem",
      borderLeft: `4px solid ${primary}`,
      paddingLeft: 20,
    },
  },
  postAuthorAvatar: {
    marginRight: 10,
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `1px solid ${primary}`,
  },
  postAuthorAvatarMd: {
    marginRight: 10,
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    border: `1px solid ${primary}`,
  },
  postAuthor: {
    fontSize: ".875rem",
    fontWeight: 400,
    fontFamily: "Open Sans",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    marginRight: 10,
  },
  shareArticleText: {
    fontWeight: 500,
    fontFamily: "Open Sans",
    fontSize: ".95rem",
    cursor: "pointer",
    verticalAlign: "-webkit-baseline-middle",
  },
  followBtn: {
    borderRadius: "99em",
    backgroundColor: primary,
    color: "white",
    fontWeight: 400,
    textTransform: "capitalize",
    marginLeft: 15,
    fontSize: ".95rem",
    padding: "7px 16px 9px",
    "&:hover": {
      backgroundColor: "#1b5e20",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  followBtnSm: {
    borderRadius: "99em",
    backgroundColor: primary,
    color: "white",
    fontWeight: 400,
    textTransform: "capitalize",
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: ".95rem",
    padding: "4px 5px 4px",
    "&:hover": {
      backgroundColor: "#1b5e20",
    },
  },
  moreFromAuthorText: {
    fontSize: "1.25rem",
    fontWeight: 500,
    marginBottom: ".5rem",
  },
  blogDescriptionText: {
    color: "#757575",
    fontSize: ".875rem",
    fontWeight: 400,
  },
  authorCard: {
    marginLeft: ".5em",
    padding: "1em 1em",
    maxWidth: 350,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  popperPaper: {
    padding: "0rem 1rem",
  },
  inputClass: {
    width: "22.5%",
    backgroundColor: "#fff",
    padding: ".25rem",
    borderBottom: "none",
  },
  sideBySide: {
    display: "contents",
  },
  giftButtonClass: {
    textTransform: "capitalize",
    fontSize: "1.1rem",
    backgroundColor: "#ff600f",
    padding: ".55rem",
    marginBottom: "2rem",
    color: "#fff",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#ff5600",
    },
  },
  hoverHand: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default function BlogTemplate({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const [adblockDetected, setAdblockDetected] = React.useState(false)
  const [giftAmount, setGiftAmount] = React.useState(1)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElTwo, setAnchorElTwo] = React.useState(null)
  const [anchorElThree, setAnchorElThree] = React.useState(null)
  const [contextAnchorEl, setContextAnchorEl] = React.useState(null)
  const [contextOpen, setContextOpen] = React.useState(false)
  const [arrowRef, setArrowRef] = React.useState(null)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [alertText, setAlertText] = React.useState("")
  const [newsletterOpen, setNewsletterOpen] = React.useState(false)
  const [guestAuthor, setGuestAuthor] = React.useState(null)
  const [highlighted, setHighlighted] = React.useState("")
  const [tweetableSelection, setTweetableSelection] = React.useState(true)
  const {
    title,
    shortTitle,
    seoTitle,
    // featured,
    createdAt,
    // updatedAt,
    blogCreatedAt,
    blogUpdatedAt,
    tags,
    // body: { body },
    image,
  } = data.contentfulBlogPost
  const { author } = data.site.siteMetadata
  const body = data.contentfulBlogPost.body.childMarkdownRemark.html
  const pathToImage = getImage(image)

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXL = useMediaQuery(theme.breakpoints.only("xl"))

  const {
    allContentfulBlogPost: { nodes: posts },
  } = data

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    // tags && console.log(tags.tags.includes("ai"))
    tags && tags.tags.includes("ai")
      ? setGuestAuthor(true)
      : setGuestAuthor(null)

    gsap.delayedCall(1, () => {
      ScrollTrigger.create({
        trigger: "#blogContent",
        pin: "#shareBar",
        start: "top top",
        // end: "+=500",
        end: "bottom bottom",
      })
    })
    gsap.delayedCall(1, () => {
      ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 1279px)": function () {
          ScrollTrigger.create({
            trigger: "#blogContent",
            pin: "#featuredContent",
            start: "top top",
            end: "+=750",
            // end: "bottom bottom",
            anticipatePin: 1,
          })
        },
      })
    })

    const url = "https://www3.doubleclick.net"
    fetch(url, {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    })
      .then((res) => {
        setAdblockDetected(false)
        // console.log(res)
      })
      .catch((err) => {
        setAdblockDetected(true)
        // console.log(err)
      })
  }, [tags])

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClickTwo = (event) => {
    setAnchorElTwo(anchorElTwo ? null : event.currentTarget)
  }
  const handleClickThree = (event) => {
    setAnchorElThree(anchorElThree ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? "transitions-popper" : undefined

  const openTwo = Boolean(anchorElTwo)
  const idTwo = openTwo ? "transitions-popper" : undefined

  const openThree = Boolean(anchorElThree)
  const idThree = openThree ? "transitions-popper" : undefined

  const handleShare = (e) => {
    e.preventDefault()

    switch (e.currentTarget.id) {
      case "facebook":
        let followurlf = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`

        window.open(`${followurlf}`, `_blank`)
        break
      case "twitter":
        let followurlt = `https://twitter.com/intent/tweet?text=${`Check out this article -  via Snappy Web Design `}&url=${
          window.location.href
        }`
        window.open(`${followurlt}`, `_blank`)
        break
      case "reddit":
        let encodedUrl = encodeURIComponent(window.location.href)
        let followurl = `http://www.reddit.com/submit?url=${encodedUrl}&title=${encodeURIComponent(
          "Article from Snappy Web Design"
        )}`
        window.open(`${followurl}`, `_blank`)
        break
      case "copy":
        navigator.clipboard.writeText(window.location.href)
        setAlertText("Link copied to clipboard")
        setAlertOpen(true)
        setAnchorEl(false)
        setAnchorElTwo(false)
        break
      default:
        break
    }
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setAlertOpen(false)
  }

  function modifyHrefLinks(html) {
    var whiteList = ["([^/]+.)?snappywebdesign.net"]
    var str =
      '(<as*(?!.*\brel=)[^>]*)(href="https?://)((?!(?:' +
      whiteList.join("|") +
      '))[^"]+)"((?!.*\brel=)[^>]*)(?:[^>]*)>'

    return html.replace(
      new RegExp(str, "igm"),
      '$1$2$3"$4 target="_blank" rel="noreferrer nofollow">'
    )
  }

  const handleContextClose = () => {
    setContextOpen(false)
  }

  const handleMouseUp = () => {
    const selection = window.getSelection()
    // Resets when the selection has a length of 0
    if (!selection || selection.anchorOffset === selection.focusOffset) {
      handleContextClose()
      return
    }

    const getBoundingClientRect = () =>
      selection.getRangeAt(0).getBoundingClientRect()

    if (selection && selection.anchorOffset !== selection.focusOffset) {
      setContextOpen(true)

      let highlight = selection.toString()

      setHighlighted(highlight)
      if (highlight.length + window.location.href.toString().length > 280) {
        setTweetableSelection(false)
      } else {
        setTweetableSelection(true)
      }
    }
    setContextAnchorEl({
      clientWidth: getBoundingClientRect().width,
      clientHeight: getBoundingClientRect().height,
      getBoundingClientRect,
    })
  }

  const handleCopyHighlight = () => {
    navigator.clipboard.writeText(highlighted)
    setAlertOpen(true)
    setAlertText("Highlight copied to clipboard")
    setContextOpen(false)
  }

  const handleTweetHighlight = () => {
    setContextOpen(false)
    window.open(
      `https://twitter.com/intent/tweet?text=${highlighted} - ${window.location.href}`,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    )
  }
  const handleLinkedInHighlight = () => {
    setContextOpen(false)
    window.open(
      `http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&summary=${highlighted}&title=${highlighted}`,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    )
  }

  const contextId = contextOpen ? "faked-reference-popper" : undefined

  return (
    <Layout>
      <Seo
        title={seoTitle || title}
        article={true}
        image={`https://${image.gatsbyImageData.images.sources[0].srcSet
          .split("?")[0]
          .substring(2)}`}
        description={shortTitle}
        blogCreatedAt={blogCreatedAt}
        blogUpdatedAt={blogUpdatedAt}
      />

      <Grid container justify="center" onMouseUp={handleMouseUp}>
        <Hidden mdDown>
          <Grid item xs={2}>
            <Grid container justify="flex-end" id="shareBar">
              <Grid
                item
                container
                justify="center"
                xs={3}
                style={{ paddingTop: "5em" }}
              >
                <Paper style={{ padding: ".15rem", marginRight: "2rem" }}>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      style={{ fontSize: ".7rem", color: "#333333" }}
                    >
                      SHARE
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleShare} id="facebook">
                      <FacebookIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleShare} id="twitter">
                      <TwitterIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleShare} id="reddit">
                      <RedditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleShare} id="copy">
                      <LinkIcon />
                    </IconButton>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={6} xl={4}>
          <Container style={{ maxWidth: 680 }} id="blogContent">
            <Grid
              container
              justify="center"
              style={{ paddingTop: "4em", paddingBottom: "6.25em" }}
            >
              <Grid item xs={12}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Typography variant="h1" className={classes.postTitle}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.postShortTitle}
                    >
                      {shortTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: "50%",
                      paddingBottom: matchesXS ? "2rem" : undefined,
                    }}
                  >
                    <Grid container direction="row">
                      <Grid item xs={11} sm={7}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                        >
                          <Grid item>
                            <Avatar
                              alt={
                                guestAuthor
                                  ? "Guest Author Profile Pic"
                                  : "Profile Pic of Snappy Web Design"
                              }
                              src={guestAuthor ? undefined : headshot}
                              className={classes.postAuthorAvatar}
                              component={Link}
                              to="/about/"
                            />
                          </Grid>
                          <Grid
                            item
                            onMouseLeave={() => setAnchorElThree(null)}
                          >
                            <Typography
                              variant="body1"
                              className={classes.postAuthor}
                              component={Link}
                              to="/about/"
                              onMouseOver={handleClickThree}
                            >
                              {guestAuthor ? "Guest" : author}
                            </Typography>
                            <Popper
                              id={idThree}
                              open={openThree}
                              anchorEl={anchorElThree}
                              placement="right"
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper className={classes.authorCard}>
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="flex-start"
                                        >
                                          <Grid item xs={2}>
                                            <Avatar
                                              alt={
                                                guestAuthor
                                                  ? "Guest Author Profile Pic"
                                                  : "Profile Pic of Snappy Web Design"
                                              }
                                              src={
                                                guestAuthor
                                                  ? undefined
                                                  : headshot
                                              }
                                              className={
                                                classes.postAuthorAvatarMd
                                              }
                                              component={Link}
                                              to="/about/"
                                            />
                                          </Grid>
                                          <Grid item xs={10}>
                                            <Typography
                                              variant="h5"
                                              style={{
                                                fontWeight: 500,
                                                fontSize: "1.45rem",
                                                marginTop: 5,
                                              }}
                                            >
                                              {guestAuthor ? "Guest" : author}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="body1"
                                          style={{
                                            fontSize: ".8rem",
                                            color: "rgba(0,0,0,1)",
                                            paddingTop: ".6em",
                                          }}
                                        >
                                          {guestAuthor
                                            ? "This post was submitted by a guest author and published through our partner program"
                                            : `I taught myself how to code in fifth grade
                                      as a hobby interest. I returned to coding
                                      in 2019 with the goal of developing a
                                      skillset that would aid me in designing &
                                      creating solutions to unique problems that
                                      could arise in my career`}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {!guestAuthor && (
                                          <Button
                                            size="small"
                                            variant="contained"
                                            className={classes.followBtnSm}
                                            onClick={() =>
                                              setNewsletterOpen(true)
                                            }
                                          >
                                            Follow
                                          </Button>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              className={classes.postDate}
                            >
                              {createdAt} ·{" "}
                              {Math.round(body.split(" ").length / 200)} min
                              read
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} sm={5}>
                        <Grid
                          container
                          direction="row-reverse"
                          justify="flex-start"
                          alignItems="center"
                        >
                          <ClickAwayListener
                            onClickAway={() => setAnchorEl(false)}
                          >
                            <Grid item>
                              <FiShare
                                onClick={handleClick}
                                size={20}
                                style={{
                                  color: "rgba(41,41,41,1)",
                                  strokeWidth: 1.5,
                                  cursor: "pointer",
                                  marginRight: 10,
                                  marginTop: 5,
                                }}
                              />

                              <Popper
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                transition
                                placement="top"
                              >
                                {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                    <List
                                      dense={true}
                                      className={classes.paper}
                                    >
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="facebook"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <FacebookIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Facebook" />
                                      </ListItem>
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="twitter"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <TwitterIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Twitter" />
                                      </ListItem>
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="reddit"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <RedditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Reddit" />
                                      </ListItem>
                                      {!matchesXS && (
                                        <ListItem
                                          button
                                          style={{ paddingTop: ".75em" }}
                                          id="copy"
                                          onClick={handleShare}
                                        >
                                          <ListItemIcon>
                                            <LinkIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Copy Link" />
                                        </ListItem>
                                      )}
                                    </List>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item xs={12}>
                        <GatsbyImage
                          image={pathToImage}
                          imgClassName={classes.postImage}
                          alt={title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {pathToImage.title && (
                          <Typography
                            className={classes.postImgSubtitle}
                            align="center"
                            variant="subtitle2"
                          >
                            {pathToImage.title}
                          </Typography>
                        )}
                      </Grid>
                      {pathToImage.description && (
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            align="center"
                            className={classes.postImgSubtitle}
                          >
                            {pathToImage.description}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      variant="body1"
                      id="contentTrigger"
                      className={classes.postBody}
                      // onMouseUp={handleMouseUp}
                      // dangerouslySetInnerHTML={{
                      //   __html: body,
                      // }}

                      dangerouslySetInnerHTML={{
                        __html: modifyHrefLinks(body),
                      }}
                    ></div>
                  </Grid>
                  <Popper
                    id={contextId}
                    open={contextOpen}
                    anchorEl={contextAnchorEl}
                    transition
                    disablePortal={false}
                    placement="top"
                    className={classes.popper}
                    modifiers={{
                      arrow: {
                        enabled: true,
                        element: arrowRef,
                      },
                    }}
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <div>
                          <span className={classes.arrow} ref={setArrowRef} />
                          <Paper elevation={10} className={classes.popperPaper}>
                            <Grid
                              container
                              alignItems="center"
                              justify="center"
                            >
                              <Grid item>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontSize: "1.10rem",
                                    textTransform: "uppercase",
                                    color: "#414141",
                                    fontWeight: 500,
                                    marginRight: theme.spacing(1),
                                  }}
                                >
                                  Share:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Tooltip
                                  arrow
                                  title={
                                    tweetableSelection
                                      ? "Share on Twitter"
                                      : "Highlight too long to tweet"
                                  }
                                >
                                  <span>
                                    <IconButton
                                      disabled={!tweetableSelection}
                                      onClick={handleTweetHighlight}
                                    >
                                      <TwitterIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip arrow title="Share on LinkedIn">
                                  <span>
                                    <IconButton
                                      onClick={handleLinkedInHighlight}
                                    >
                                      <LinkedIn />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip arrow title="Copy to clipboard">
                                  <span>
                                    <IconButton onClick={handleCopyHighlight}>
                                      <LinkIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Paper>
                        </div>
                      </Fade>
                    )}
                  </Popper>
                  <Grid item xs={12} style={{ marginTop: "2em" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <ClickAwayListener
                            onClickAway={() => setAnchorElTwo(false)}
                          >
                            <Grid item>
                              <FiShare
                                onClick={handleClickTwo}
                                size={20}
                                style={{
                                  color: "rgba(41,41,41,1)",
                                  strokeWidth: 1.5,
                                  cursor: "pointer",
                                  marginRight: 10,
                                  marginTop: 5,
                                }}
                              />
                              <span
                                className={classes.shareArticleText}
                                onClick={handleClickTwo}
                                aria-hidden="true"
                              >
                                Share Article ·{" "}
                              </span>
                              <Link
                                className={classes.shareArticleText}
                                style={{
                                  color: `${primary}`,
                                  fontWeight: 500,
                                  textDecoration: "underline",
                                }}
                                to="/contact/"
                              >
                                Hire me
                              </Link>
                              <Popper
                                id={idTwo}
                                open={openTwo}
                                anchorEl={anchorElTwo}
                                transition
                                placement="bottom"
                              >
                                {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                    <List
                                      dense={true}
                                      className={classes.paper}
                                    >
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="facebook"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <FacebookIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Facebook" />
                                      </ListItem>
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="twitter"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <TwitterIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Twitter" />
                                      </ListItem>
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="reddit"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <RedditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Reddit" />
                                      </ListItem>
                                      <ListItem
                                        button
                                        style={{ paddingTop: ".75em" }}
                                        id="copy"
                                        onClick={handleShare}
                                      >
                                        <ListItemIcon>
                                          <LinkIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Copy Link" />
                                      </ListItem>
                                    </List>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <CommentSection /> */}
                </Grid>
                {/* <Divider
              style={{ marginTop: "3.125em", marginBottom: "3.125em" }}
            /> */}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            style={{ marginTop: "5rem" }}
            // justify={matchesMD ? "center" : undefined}
            id="featuredContent"
          >
            {adblockDetected && (
              <Grid item>
                <Container
                  maxWidth={matchesMD ? "sm" : "xs"}
                  style={{
                    marginLeft: matchesXL ? "4rem" : undefined,
                    marginBottom: "1rem",
                  }}
                >
                  <Grid item container justify="center" xs={12}>
                    <img
                      src={AdBlockerHand}
                      style={{
                        height: 100,
                        color: "#ff7630",
                        marginBottom: "1rem",
                      }}
                      alt="Ad Blocker Detected"
                    />
                  </Grid>
                  <Typography
                    variant="h2"
                    paragraph
                    style={{
                      fontSize: "2.25rem",
                      fontWeight: 400,
                      color: "#ff7630",
                      marginBottom: ".25rem",
                    }}
                  >
                    Ad Blocker Detected!
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    style={{
                      fontSize: "1.3rem",
                      marginBottom: ".5rem",
                      fontWeight: 500,
                    }}
                  >
                    I get it. I use AdBlock sometimes, too.
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    style={{ fontSize: ".9rem" }}
                  >
                    Please consider gifting coffee in lieu of ads :) <br />
                    I'll shout you out if you leave a note!
                  </Typography>
                  <Grid item container>
                    <Paper
                      style={{
                        backgroundColor: "rgba(255,118,48,.2)",
                        border: "1px solid rgba(255,118,48,.6)",
                        borderRadius: 0,
                        marginBottom: "1rem",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            display: "inline-block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <Grid container justify="center" alignItems="center">
                            <IconButton
                              onClick={() => {
                                let current = giftAmount
                                if (current === 0 || current === 1) {
                                  return
                                } else {
                                  setGiftAmount(current - 1)
                                }
                              }}
                            >
                              <RemoveCircleOutlineIcon fontSize="large" />
                            </IconButton>
                            <TextField
                              id="input-with-icon-textfield"
                              type="number"
                              value={giftAmount}
                              classes={{
                                root: classes.sideBySide,
                              }}
                              InputProps={{
                                classes: { root: classes.inputClass },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={coffee}
                                      alt="Coffee Cup"
                                      style={{ maxWidth: 25, height: 25 }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <IconButton
                              onClick={() => {
                                let current = giftAmount
                                setGiftAmount(current + 1)
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="large" />
                            </IconButton>
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Paper>
                  </Grid>
                  <form
                    // target="_window"
                    target="_blank"
                    action="https://www.paypal.com/cgi-bin/webscr"
                    method="post"
                  >
                    <input type="hidden" name="cmd" value="_donations" />
                    <input
                      type="hidden"
                      name="business"
                      value="ERFLHRHX8YDGN"
                    />
                    <input
                      type="hidden"
                      name="item_name"
                      value="Gift in Lieu of Ads"
                    />
                    <input
                      type="hidden"
                      name="amount"
                      value={`${giftAmount * 2}.00`}
                    />
                    <input type="hidden" name="currency_code" value="USD" />
                    {/* <input type="hidden" name="no_note" value="1" />
                    <input type="hidden" name="no_shipping" value="2" /> */}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        className={classes.giftButtonClass}
                      >
                        Gift ${giftAmount * 2}.00
                      </Button>
                    </Grid>
                  </form>
                </Container>
              </Grid>
            )}
            <Grid item xs={12} style={{ width: "100%" }}>
              <AdSense.Google
                style={{ display: "block" }}
                client="ca-pub-6369354476906630"
                slot="5707033030"
                format="auto"
                responsive="true"
              />
            </Grid>
            <Grid
              container
              justify={matchesMD ? "center" : undefined}
              style={{ marginTop: matchesMD ? "1rem" : undefined }}
            >
              <Grid item>
                <Container
                  maxWidth={matchesMD ? "sm" : "xs"}
                  style={{ marginLeft: matchesXL ? "4rem" : undefined }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: matchesMD ? undefined : "1rem",
                      paddingBottom: matchesMD ? "3rem" : undefined,
                    }}
                  >
                    <Grid container justify="space-evenly">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          paragraph
                          style={{
                            textTransform: "uppercase",
                            fontWeight: 500,
                            fontSize: "1.1rem",
                          }}
                        >
                          Most popular posts
                        </Typography>
                      </Grid>

                      {data.featuredPosts.nodes.map((post, i) => {
                        const image = getImage(post.image)
                        const slug = slugify(post.title, { lower: true })

                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            key={post.id}
                            onClick={() => navigate(`/blog/${slug}/`)}
                            className={classes.hoverHand}
                          >
                            <Grid item container xs={1}>
                              <Typography
                                variant="h4"
                                paragraph
                                style={{
                                  color: "#333333",
                                  fontStyle: "Open Sans",
                                  opacity: 0.3,
                                }}
                              >
                                {i + 1}.
                              </Typography>
                            </Grid>
                            <Grid item container xs={6} alignItems="center">
                              <Typography
                                variant="body1"
                                paragraph
                                component={Link}
                                to={`/blog/${slug}/`}
                                style={{
                                  marginLeft: ".5rem",
                                  fontSize: ".9rem",
                                  fontWeight: 400,
                                  textDecoration: "none",
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {post.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              xs={4}
                            >
                              <GatsbyImage image={image} alt={post.title} />
                            </Grid>
                            <Divider
                              style={{
                                marginTop: ".25rem",
                                marginBottom: "0.29525rem",
                                width: "61%",
                              }}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: "1rem",
                      paddingBottom: matchesMD ? "3rem" : undefined,
                    }}
                  >
                    <Grid container justify="space-evenly">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          paragraph
                          style={{
                            textTransform: "uppercase",
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            color: "#A28C6D",
                          }}
                        >
                          For Developers
                        </Typography>
                      </Grid>

                      {data.featuredDevPosts.nodes.map((post, i) => {
                        const image = getImage(post.image)
                        const slug = slugify(post.title, { lower: true })

                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            key={post.id}
                            onClick={() => navigate(`/blog/${slug}/`)}
                            className={classes.hoverHand}
                          >
                            <Grid item container xs={1}>
                              <Typography
                                variant="h4"
                                paragraph
                                style={{
                                  color: "#333333",
                                  fontStyle: "Open Sans",
                                  opacity: 0.3,
                                }}
                              >
                                {i + 1}.
                              </Typography>
                            </Grid>
                            <Grid item container xs={6} alignItems="center">
                              <Typography
                                variant="body1"
                                paragraph
                                component={Link}
                                to={`/blog/${slug}/`}
                                style={{
                                  marginLeft: ".5rem",
                                  fontSize: ".9rem",
                                  fontWeight: 400,
                                  textDecoration: "none",
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {post.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              xs={4}
                            >
                              <GatsbyImage image={image} alt={post.title} />
                            </Grid>
                            <Divider
                              style={{
                                marginTop: ".25rem",
                                marginBottom: "0.29525rem",
                                width: "61%",
                              }}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: "1rem",
                      paddingBottom: matchesMD ? "3rem" : undefined,
                    }}
                  >
                    <Grid container justify="space-evenly">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          paragraph
                          style={{
                            textTransform: "uppercase",
                            fontWeight: 500,
                            opacity: 0.6,
                            fontSize: "1.1rem",
                          }}
                        >
                          For Business Owners
                        </Typography>
                      </Grid>
                      {data.featuredBizPosts.nodes.map((post, i) => {
                        const image = getImage(post.image)
                        const slug = slugify(post.title, { lower: true })

                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            key={post.id}
                            onClick={() => navigate(`/blog/${slug}/`)}
                            className={classes.hoverHand}
                          >
                            <Grid item container xs={1}>
                              <Typography
                                variant="h4"
                                paragraph
                                style={{
                                  color: "#333333",
                                  fontStyle: "Open Sans",
                                  opacity: 0.3,
                                }}
                              >
                                {i + 1}.
                              </Typography>
                            </Grid>
                            <Grid item container xs={6} alignItems="center">
                              <Typography
                                variant="body1"
                                paragraph
                                component={Link}
                                to={`/blog/${slug}/`}
                                style={{
                                  marginLeft: ".5rem",
                                  fontSize: ".9rem",
                                  fontWeight: 400,
                                  textDecoration: "none",
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {post.title}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              xs={4}
                            >
                              <GatsbyImage image={image} alt={post.title} />
                            </Grid>
                            <Divider
                              style={{
                                marginTop: ".25rem",
                                marginBottom: "0.29525rem",
                                width: "61%",
                              }}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Container
            style={{ backgroundColor: "rgba(250,250,250,1)" }}
            maxWidth={false}
          >
            <Grid item xs={12}>
              <Container style={{ maxWidth: 680 }}>
                <Grid container direction="row" style={{ paddingTop: "2em" }}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          paragraph
                          className={classes.moreFromAuthorText}
                        >
                          More from {author}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          className={classes.followBtn}
                          onClick={() => setNewsletterOpen(true)}
                        >
                          Follow
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography
                          variant="body1"
                          className={classes.blogDescriptionText}
                        >
                          Subscribe to the Snappy Web Design Blog to get
                          notified when a new post goes live. We'll never share
                          your e-mail address or use it for any reason other
                          than to share new posts.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container style={{ maxWidth: 680 }}>
                <Grid
                  container
                  justify="center"
                  style={{ paddingTop: "6.25em", paddingBottom: "6.25em" }}
                >
                  {posts.map((post) => {
                    const image = getImage(post.image)
                    const slug = slugify(post.title, { lower: true })

                    return (
                      <Grid item xs={12} key={post.id}>
                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              className={classes.postDate}
                              style={{ marginBottom: ".75em" }}
                            >
                              Published {post.createdAt}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Link
                              to={`/blog/${slug}/`}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                variant="h6"
                                className={classes.postTitle}
                              >
                                {post.title}
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              className={classes.postShortTitle}
                            >
                              {post.shortTitle}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container direction="column">
                              <Grid item xs={12}>
                                <Link
                                  to={`/blog/${slug}/`}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <GatsbyImage
                                    image={image}
                                    imgClassName={classes.postImage}
                                    alt={post.title}
                                  />
                                </Link>
                              </Grid>
                              <Grid item xs={12}>
                                {post.image.title && (
                                  <Typography
                                    className={classes.postImgSubtitle}
                                    align="center"
                                    variant="subtitle2"
                                  >
                                    {post.image.title}
                                  </Typography>
                                )}
                              </Grid>
                              {post.image.description && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle2"
                                    align="center"
                                    className={classes.postImgSubtitle}
                                  >
                                    {post.image.description}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              className={classes.postBody}
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.body.childMarkdownRemark.html.substring(
                                    0,
                                    500
                                  ) + "...",
                              }}
                            ></Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Link
                              to={`/blog/${slug}/`}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <Typography
                                variant="body1"
                                className={classes.postFooterLink}
                              >
                                Read more ·{" "}
                                {Math.round(
                                  post.body.body.split(" ").length / 200
                                )}{" "}
                                minute read
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                        <Divider
                          style={{
                            marginTop: "3.125em",
                            marginBottom: "3.125em",
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Container>
            </Grid>
          </Container>
        </Grid>
      </Grid>

      <Dialog
        open={newsletterOpen}
        onClose={() => setNewsletterOpen(false)}
        aria-labelledby="newsletter-form-dialog"
        fullScreen={fullScreen}
      >
        <form name="newsletter" method="POST" netlify data-netlify="true">
          <input type="hidden" name="form-name" value="newsletter" />
          <DialogTitle
            id="newsletter-form-title"
            style={{
              marginTop: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              color: theme.palette.common.accent,
            }}
          >
            Subscribe
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setNewsletterOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ textAlign: "center" }}>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: matchesXS ? "80%" : undefined,
            }}
          >
            <Button
              onClick={() => setNewsletterOpen(false)}
              size="large"
              style={{
                color: theme.palette.common.accent,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setNewsletterOpen(false)}
              style={{ color: theme.palette.common.accent }}
              as="button"
              type="submit"
            >
              Subscribe
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          style={{ backgroundColor: `${primary}` }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export const query = graphql`
  query getSingleBlog($title: String) {
    contentfulBlogPost(title: { eq: $title }) {
      title
      seoTitle
      shortTitle
      featured
      createdAt(formatString: "MMM D")
      updatedAt(formatString: "MMM D")
      blogCreatedAt: createdAt(formatString: "YYYY-MM-DD")
      blogUpdatedAt: updatedAt(formatString: "YYYY-MM-DD")
      tags {
        tags
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 680
          quality: 95
        )
      }
    }
    site {
      siteMetadata {
        author
      }
    }
    featuredPosts: allContentfulBlogPost(
      filter: { featured: { eq: true } }
      sort: { fields: createdAt }
    ) {
      nodes {
        id
        title
        image {
          gatsbyImageData(
            width: 111
            height: 62
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
    featuredDevPosts: allContentfulBlogPost(
      filter: {
        tags: { tags: { eq: "development" } }
        featured: { eq: false }
        title: { ne: $title }
      }
      limit: 3
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        id
        title
        image {
          gatsbyImageData(
            width: 111
            height: 62
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
    featuredBizPosts: allContentfulBlogPost(
      filter: {
        tags: { tags: { eq: "business" } }
        featured: { eq: false }
        title: { ne: $title }
      }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        title
        image {
          gatsbyImageData(
            width: 111
            height: 62
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
    allContentfulBlogPost(
      filter: { title: { ne: $title }, tags: { tags: { nin: "ai" } } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        title
        shortTitle
        createdAt(formatString: "MMMM d")
        updatedAt(formatString: "MMMM d")
        body {
          body
          childMarkdownRemark {
            html
          }
        }
        tags {
          tags
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
          title
          description
        }
      }
    }
  }
`
